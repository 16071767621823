import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  FormControl,
  Box,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  Modal,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import DeleteConfirmationDialog from '../components/Dialogues/DeleteConfirmation';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import islandLIST from '../_mock/island';

//Firebase
import { collection, onSnapshot, getDoc, getDocs, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';

//Toaster
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'island', label: 'island', alignRight: false },
  { id: 'users', label: 'Total Users', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_island) => _island.island.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  p: 4,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
};

export default function Listings() {
  const [open, setOpen] = useState(null);
  const [openView, setOpenView] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedislandToDelete, setSelectedislandToDelete] = useState(null);

  const [selectedislandToView, setSelectedislandToView] = useState(null);

  const [islandLIST, setislandLIST] = useState([]);

  const [isupdate, setisupdate] = useState('');

  const [Name, setName] = useState('');

  const [isProcessing, setisProcessing] = useState(false);

  const [loading, setLoading] = useState(false); //To check if data if loaded or not from collection.

  useEffect(() => {
    setLoading(true);

    // Fetch the islands list
    const islandsDocRef = doc(db, 'data', 'islands');

    // Listen for changes using onSnapshot
    const unsubscribeIslands = onSnapshot(islandsDocRef, (islandSnapshot) => {
      const islandData = islandSnapshot.data();
      const islands = islandData ? islandData.list : [];

      // Fetch user data
      const usersCollectionRef = collection(db, 'users');

      // Listen for changes using onSnapshot
      const unsubscribeUsers = onSnapshot(usersCollectionRef, (usersSnapshot) => {
        const islandUserCountMap = new Map();

        // Count users for each island
        usersSnapshot.forEach((userDoc) => {
          const userData = userDoc.data();
          const userIsland = userData.island;

          if (islandUserCountMap.has(userIsland)) {
            islandUserCountMap.set(userIsland, islandUserCountMap.get(userIsland) + 1);
          } else {
            islandUserCountMap.set(userIsland, 1);
          }
        });

        // Create the island list with user counts
        const islandListWithCounts = islands.map((island) => ({
          island: island,
          totalUser: islandUserCountMap.get(island) || 0,
        }));

        setislandLIST(islandListWithCounts);
        setLoading(false);
      });

      // Return the unsubscribe function to clean up the listener
      return () => {
        unsubscribeUsers(); // Unsubscribe from users collection changes
      };
    });

    // Return the unsubscribe function to clean up the listener
    return () => {
      unsubscribeIslands(); // Unsubscribe from islands document changes
    };
  }, []);

  const handleCloseView = () => {
    setOpenView(null);
    handleCloseMenu();
  };
  const handleOpenView = (event) => {
    setOpenView(true);
  };
  const handleOpenDeleteDialog = (island) => {
    setDeleteDialogOpen(true);
    setSelectedislandToDelete(island);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedislandToDelete(null);
    setDeleteDialogOpen(false);
    handleCloseMenu();
  };
  const handleOpenMenu = (event, island) => {
    setSelectedislandToView(island);
    setSelectedislandToDelete(island.id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = islandLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
    setName('');
  };
  const handleOpenUpdate = (name = '') => {
    setName(name);
    setisupdate(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setName('');
    setisupdate('');
    setisProcessing(false);
  };

  const addisland = () => {
    if (!Name) {
      toast.error('island Cant be Empty');
      return;
    }
    setisProcessing(true);

    if (isupdate) {
      const updatedislandList = islandLIST.map((island) => (island.island === isupdate ? Name : island.island));
      updateislands(updatedislandList);
    } else {
      // Check if the island already exists in islandLIST
      const doesIslandExist = islandLIST.some((item) => {
        if (typeof item.island === 'object' && item.island.island) {
          return item.island.island === Name;
        } else if (typeof item.island === 'string') {
          return item.island === Name;
        }
        return false;
      });

      if (doesIslandExist) {
        toast.error('island already exists');
        setisProcessing(false);
        return;
      }

      const islandNames = islandLIST.map((item) => {
        if (typeof item.island === 'object' && item.island.island) {
          return item.island.island;
        } else if (typeof item.island === 'string') {
          return item.island;
        }
        return ''; // Replace with a default value if needed
      });

      const updatedislandList = [...islandNames, Name];
      // Add the updated island to islandLIST
      console.log(updatedislandList);
      updateislands(updatedislandList);
    }
  };

  const updateislands = async (updatedislandList) => {
    const islandsDocRef = doc(db, 'data', 'islands');
    const islandsDoc = await getDoc(islandsDocRef);

    if (islandsDoc.exists()) {
      // Document exists, update it
      updateDoc(islandsDocRef, { list: updatedislandList })
        .then(() => {
          handleClose();
          toast.success('island Updated');
        })
        .catch((error) => {
          handleClose();
          toast.error('Error! unable to Update island');
          console.log(error);
        });
    } else {
      // Document doesn't exist, create a new one
      setDoc(islandsDocRef, { list: updatedislandList })
        .then(() => {
          handleClose();
          toast.success('island Added');
        })
        .catch((error) => {
          handleClose();
          toast.error('Error! unable to Add island');
          console.log(error);
        });
    }
  };

  const deleteSelectedData = async () => {
    try {
      console.log(selected);

      // Filter out elements that are present in the selected array
      const updatedList = islandLIST
        .map((islandObj) => islandObj.island)
        .filter((item) => {
          if (typeof item === 'object' && item) {
            return !selected.includes(item.island);
          } else if (typeof item === 'string') {
            return !selected.includes(item);
          }
          return true;
        });
      console.log(updatedList);
      // Update the document in Firebase with the updated list
      await updateDoc(doc(db, 'data', 'islands'), { list: updatedList });

      toast.info('Selected islands deleted');
    } catch (error) {
      toast.error('Error! Unable to delete selected islands');
      console.log(error);
    }
  };

  const onDelete = async () => {
    try {
      // Update the islandLIST state

      const updatedList = islandLIST
        .map((islandObj) => islandObj.island)
        .filter((islandName) => islandName !== selectedislandToDelete);
      // Update the document in Firebase
      await updateDoc(doc(db, 'data', 'islands'), { list: updatedList });

      toast.info('island Deleted');
    } catch (error) {
      toast.error('Error! Unable to Delete island');
      console.log(error);
    }

    handleCloseDeleteDialog();
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - islandLIST.length) : 0;

  const filteredislands = applySortFilter(islandLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredislands.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> islands | vPro </title>
      </Helmet>

      <Container>
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete island</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete the island?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={() => onDelete()} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <Box style={{ display: 'flex', justifyContent: 'end' }}>
                <Iconify icon="mdi:close-circle" onClick={handleClose} style={{ cursor: 'pointer', color: 'gray' }} />
              </Box>
              <h2 className="mb-4" mb={3}>
                {!isupdate ? 'Add New island' : 'Update island'}
              </h2>

              <FormControl fullWidth size="small">
                <TextField
                  className="mb-4"
                  id="outlined-basic"
                  label="Enter Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              <Button
                fullWidth
                className="mt-2 muibtn"
                variant="contained"
                color="primary"
                disabled={isProcessing}
                onClick={() => addisland()}
              >
                {isProcessing ? (
                  <CircularProgress sx={{ color: 'white' }} size={27} />
                ) : (
                  <>
                    <Iconify icon={'mdi:plus-thick'} style={{ marginRight: 10 }} />
                    {!isupdate ? 'Add island' : 'Update island'}
                  </>
                )}
              </Button>
            </div>
          </Box>
        </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All islands
          </Typography>

          <div>
            <Button onClick={() => handleOpen()} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              Add New island
            </Button>
          </div>
        </Stack>
        <Card>
          <UserListToolbar
            onDelete={deleteSelectedData}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={islandLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredislands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((island) => {
                    const selectedisland = selected.indexOf(island.island) !== -1;

                    return (
                      <TableRow hover key={island.island} tabIndex={-1} role="checkbox" selected={selectedisland}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedisland} onChange={(event) => handleClick(event, island.island)} />
                        </TableCell>

                        <TableCell component="th" scope="row" className="ms-auto" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {island.island}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" className="" noWrap>
                            {island.totalUser}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleOpenUpdate(island.island);
                            }}
                            sx={{ mr: 2 }}
                          >
                            <Iconify icon={'mdi:lead-pencil'} sx={{ mr: 2 }} />
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            className="bg-danger"
                            onClick={() => handleOpenDeleteDialog(island.island)}
                          >
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={islandLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
