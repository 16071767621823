import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import AddUser from './pages/AddUser';
import Addiland from './pages/Addiland';
import AddCurrency from './pages/AddCurrency';
import Listings from './pages/Listings';
import AddCategory from './pages/AddCategory';
import AddService from './pages/AddService';
import Reviews from './pages/Reviews';
import AddCourse from './pages/AddCourse';
import Settings from './pages/Settings';
import CourseContent from './pages/CourseContent';
import AddAdmin from './pages/AddAdmin';
import Orders from './pages/Orders';
import Jobs from './pages/Jobs';
import Event from './pages/Event';
import Notifications from './pages/Notifications';

// ----------------------------------------------------------------------

//context
import ProtectedRoute from './Context/ProtectedRoute';
import LogoutRoute from './pages/LogoutRoute';
import ServiceListings from './pages/ServiceListing';
export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'adduser', element: <AddUser /> },
        { path: 'addadmin', element: <AddAdmin /> },
        { path: 'category', element: <AddCategory /> },
        { path: 'services', element: <AddService /> },
        { path: 'islands', element: <Addiland /> },
        { path: 'currency', element: <AddCurrency /> },
        { path: 'reviews', element: <Reviews /> },
        { path: 'addcourses/:DocId?', element: <AddCourse /> },
        { path: 'user', element: <UserPage /> },
        { path: 'listings', element: <Listings /> },
        { path: 'service_listings', element: <ServiceListings /> },
        { path: 'events', element: <Event /> },
        { path: 'orders', element: <Orders /> },
        { path: 'jobs', element: <Jobs /> },
        { path: 'settings', element: <Settings /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'couredetails', element: <CourseContent /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'logout',
      element: <LogoutRoute />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
