// ----------------------------------------------------------------------

const account = {
  displayName: 'Syed Mushahid',
  email: 'syed@mushahid.com',
  role: 'Admin',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
