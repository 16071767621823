const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: 'mdi:view-dashboard',
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: 'mdi:account',
  },
  {
    title: 'listings',
    path: '/dashboard/listings',
    icon: 'material-symbols:list',
  },
  {
    title: 'service listings',
    path: '/dashboard/service_listings',
    icon: 'material-symbols:list',
  },
  {
    title: 'Jobs',
    path: '/dashboard/jobs',
    icon: 'ic:round-work',
  },
  {
    title: 'Events',
    path: '/dashboard/events',
    icon: 'clarity:event-line',
  },
  {
    title: 'Categories',
    path: '/dashboard/category',
    icon: 'mdi:shape',
  },
  {
    title: 'Services Categories',
    path: '/dashboard/services',
    icon: 'mdi:account-service',
  },
  {
    title: 'IsLands',
    path: '/dashboard/islands',
    icon: 'fontisto:island',
  },
  {
    title: 'Currency',
    path: '/dashboard/currency',
    icon: 'grommet-icons:currency',
  },
  {
    title: 'Notification',
    path: '/dashboard/notifications',
    icon: 'clarity:notification-solid',
  },

  {
    title: 'Logout',
    path: '/logout',
    icon: 'mdi:logout-variant',
  },
];

export default navConfig;
