import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import DeleteConfirmationDialog from '../components/Dialogues/DeleteConfirmation';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';

//Firebase
import { collection, onSnapshot, getDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';

//Toaster
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'position', label: 'Position', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'email', label: 'Apply Email', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'salary', label: 'Salary', alignRight: false },
  { id: 'employeerId', label: 'Employeer', alignRight: false },
  { id: 'jobtype', label: 'Job Type', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.position.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Jobs() {
  const [open, setOpen] = useState(null);
  const [openView, setOpenView] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedJobToDelete, setSelectedJobToDelete] = useState(null);

  const [selectedJobToView, setSelectedJobToView] = useState(null);

  const [USERLIST, setUSERLIST] = useState([]);

  const [loading, setLoading] = useState(false); //To check if data if loaded or not from collection.

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      let collectionRef = collection(db, 'jobs');

      onSnapshot(collectionRef, async (data) => {
        const promises = [];
        setUSERLIST([]);
        data.docs.forEach((data) => {
          const jobData = data.data();
          const employerId = jobData.employerId;

          // Query the users collection to get the fullName based on employerId
          const userDocRef = doc(db, 'users', employerId);
          const promise = getDoc(userDocRef).then((userDoc) => {
            if (userDoc.exists()) {
              const userData = userDoc.data();
              const fullName = userData.fullName;

              // Combine the fullName with other job data
              const jobWithFullName = { ...jobData, id: data.id, employeerFullName: fullName };
              setUSERLIST((rows) => [...rows, jobWithFullName]);
            }
          });

          promises.push(promise);
        });

        // Wait for all promises to complete
        await Promise.all(promises);

        setLoading(false);
      });
    };
    getData();
  }, []);

  const handleCloseView = () => {
    setOpenView(null);
    handleCloseMenu();
  };
  const handleOpenView = (event) => {
    setOpenView(true);
  };
  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedJobToDelete(null);
    setDeleteDialogOpen(false);
    handleCloseMenu();
  };
  const handleOpenMenu = (event, user) => {
    setSelectedJobToView(user);
    setSelectedJobToDelete(user.id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const deleteSelectedData = async () => {
    selected.forEach((documentId) => {
      if (documentId) {
        deleteDoc(doc(db, 'jobs', documentId))
          .then(() => {
            toast.info('Job with ID ' + documentId + ' deleted successfully!');
            // Additional handling if needed
          })
          .catch((error) => {
            toast.error('Error deleting job with ID ' + documentId);
            console.error('Error deleting job with ID ' + documentId, error);
            // Additional error handling if needed
          });
      }
    });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> jobs | vPro </title>
      </Helmet>

      <Container>
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          heading="Delete Job"
          subtitle="job"
          docId={selectedJobToDelete}
          colName="jobs"
          onClose={handleCloseDeleteDialog}
        />

        <Dialog open={Boolean(openView)} onClose={handleCloseView} fullWidth maxWidth="sm">
          <DialogTitle>Job Details</DialogTitle>
          <DialogContent>
            {selectedJobToView && (
              <div className="row">
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="h6">{selectedJobToView.position}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Id:</TableCell>
                        <TableCell>{selectedJobToView.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Apply Email:</TableCell>
                        <TableCell>{selectedJobToView.applyEmail}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Company:</TableCell>
                        <TableCell>{selectedJobToView.companyName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Employeer:</TableCell>
                        <TableCell>{selectedJobToView.employeerFullName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Salary:</TableCell>
                        <TableCell>{selectedJobToView.salary}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Date:</TableCell>
                        <TableCell>{selectedJobToView.date?.toDate().toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Location:</TableCell>
                        <TableCell>{selectedJobToView.location}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Hours:</TableCell>
                        <TableCell>{selectedJobToView.hours}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Job Type:</TableCell>
                        <TableCell>{selectedJobToView.jobType}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Qualification:</TableCell>
                        <TableCell>{selectedJobToView.qualification}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Website:</TableCell>
                        <TableCell>{selectedJobToView.website}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Description:</TableCell>
                        <TableCell>{selectedJobToView.description}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All jobs
          </Typography>
          <div>
            {/* <Link to="/dashboard/addadmin">
              <Button variant="contained" className="me-4" startIcon={<Iconify icon="eva:plus-fill" />}>
                New Admin
              </Button>
            </Link> */}
            {/* <Link to="/dashboard/adduser">
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                New User
              </Button>
            </Link> */}
          </div>
        </Stack>
        <Card>
          <UserListToolbar
            onDelete={deleteSelectedData}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      companyName,
                      date,
                      employeerFullName,
                      id,
                      applyEmail,
                      jobType,
                      salary,
                      position,
                      location,
                    } = row;
                    const selectedJob = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedJob}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedJob} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {position}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{companyName}</TableCell>
                        <TableCell align="left">{date?.toDate().toLocaleString()}</TableCell>
                        <TableCell align="left">{applyEmail}</TableCell>
                        <TableCell align="left">{location}</TableCell>

                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}
                        <TableCell align="left">{salary}</TableCell>
                        <TableCell align="left">{employeerFullName}</TableCell>
                        <TableCell align="left">{jobType}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenView()} sx={{ mr: 2 }}>
          <Iconify icon={'carbon:view-filled'} sx={{ mr: 2 }} />
          View
        </MenuItem>

        <MenuItem onClick={() => handleOpenDeleteDialog()} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
