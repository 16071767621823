import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import {
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Stack,
  Container,
  Button,
  TextField,
  FormControl,
  Box,
  Modal,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';
import { collection, getDocs, setDoc, updateDoc, onSnapshot, doc, query, orderBy, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, ref, uploadBytes } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify/Iconify';
import { db, storage } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  p: 4,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
};

export default function Notification() {
  const [rows, setRows] = useState([]);
  const [isupdate, setisupdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setisProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [DocId, setDocId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [UploadImage, setUploadImage] = useState(null);
  const navigate = useNavigate();
  const functions = getFunctions();

  const sendPushNotificationToTopic = httpsCallable(functions, 'sendPushNotificationToTopic');
  const sendEmailsToUsers = httpsCallable(functions, 'sendEmailsToUsers');

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'notifications'), (snapshot) => {
      setLoading(true);
      const updatedRows = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRows(updatedRows);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setTitle('');
    setDescription('');
  };

  const handleClose = () => {
    setOpen(false);
    setTitle('');
    setUploadImage('');
    setDescription('');
    setisupdate(false);
    setisProcessing(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDocId('');
    setisProcessing(false);
  };

  const deleteNotification = () => {
    setisProcessing(true);
    deleteDoc(doc(db, 'notifications', DocId))
      .then(() => {
        toast.info('Notification Deleted');
        handleCloseDialog();
      })
      .catch((error) => {
        handleCloseDialog();
        toast.error('Error! Unable to Delete Notification');
        console.log(error);
      });
  };

  const sendNotification = async (image) => {
    const topic = 'announcements';
    const message = {
      topic: topic,
      notification: {
        title: title,
        body: description,
      },
      data: {
        imageUrl: image,
      },
    };
    const res = await sendPushNotificationToTopic({ topic, message });

    if (res.data) {
      if (isupdate) {
        await updateDoc(doc(db, 'notifications', DocId), {
          title: title,
          imageUrl: image,
          description: description,
        });
        toast.success('Notification Sent');
        toast.info('Sending Emails. This can take time depending on total users.');
        sendEmailsToUsers({ title: title, description: description, imageUrl: image })
          .then((res) => {
            console.log(res);
            setisProcessing(false);
            toast.success('Emails Sent');
            handleClose();
          })
          .catch((error) => {
            console.log(error);
            setisProcessing(false);
            toast.error('Error while sending email to some users.');
          });
      } else {
        const newDoc = doc(collection(db, 'notifications'));
        const docId = newDoc.id;
        await setDoc(newDoc, {
          title: title,
          description: description,
          imageUrl: image,
          createdOn: new Date(),
          id: docId,
        });
        toast.success('Notification Sent');
        toast.info('Sending Emails. This can take time depending on total users.');
        sendEmailsToUsers({ title: title, description: description, imageUrl: image })
          .then((res) => {
            console.log(res);
            setisProcessing(false);
            toast.success('Emails Sent');
            handleClose();
          })
          .catch((error) => {
            console.log(error);
            setisProcessing(false);
            toast.error('Error while sending email to some users.');
          });
      }
    }
  };
  const addNotification = async () => {
    if (!title) {
      toast.error('Notification Title Cant be Empty');
      return;
    }
    setisProcessing(true);
    var image = '';

    try {
      if (UploadImage) {
        const storageRef = ref(storage, `/Notifications/notificication_image${new Date()}`);
        const uploadTask = uploadBytesResumable(storageRef, UploadImage);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          },
          (err) => console.log(err),
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              image = url;
              sendNotification(image);
            } catch (error) {
              console.log('Error While Sending Notification', error);
              setisProcessing(false);
            }
          }
        );
      } else {
        sendNotification((image = ''));
      }
    } catch (error) {
      console.log('Error While Sending Notification', error);
      setisProcessing(false);
    }
  };

  const columns = [
    {
      field: 'imageUrl',
      headerName: 'Image',
      headerClassName: 'bg-light',
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value} // Assuming `params.value` is the URL of the image
          alt="Image"
          style={{ width: '60px', height: '60px' }}
        />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      headerClassName: 'bg-light',
      flex: 1, // Distribute available space evenly
    },

    {
      field: 'description',
      headerName: 'Description',
      headerClassName: 'bg-light',
      flex: 1, // Distribute available space evenly
    },
    {
      field: 'createdOn',
      headerName: 'Sent On',
      headerClassName: 'bg-light',
      flex: 1,
      renderCell: (params) => {
        return <span>{params.value ? params.value.toDate().toLocaleString() : ''}</span>;
      },
    },

    {
      field: 'Action',
      headerClassName: 'bg-light',
      flex: 1, // Distribute available space evenly
      editable: false,
      renderCell: ActionButton,
      align: 'right',
    },
  ];
  function handleClickOpen(data) {
    setOpenDialog(true);
    setDocId(data);
  }
  function ActionButton(params) {
    return (
      <>
        {/* <Button
            variant="contained"
            color="primary"
            className="mx-2"
            size="small"
            startIcon={<Iconify icon="mdi:pencil" />}
            onClick={() => {
              handleOpenUpdate(params.row.name, params.row.id);
            }}
          >
            Edit
          </Button> */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="bg-danger mx-2"
          onClick={() => handleClickOpen(params.row.id)}
          startIcon={<Iconify icon="mdi:delete" />}
        >
          Delete
        </Button>
      </>
    );
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure to Delete the Notification from history?'}</DialogTitle>

        {isProcessing ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={() => deleteNotification()}>Yes</Button>
            <Button onClick={handleCloseDialog} autoFocus>
              No
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Box style={{ display: 'flex', justifyContent: 'end' }}>
              <Iconify icon="mdi:close-circle" onClick={handleClose} style={{ cursor: 'pointer', color: 'gray' }} />
            </Box>
            <h2 className="mb-4" mb={3}>
              {!isupdate ? 'Send New Notification' : 'Update Notification'}
            </h2>
            <FormControl fullWidth size="small">
              <TextField
                className="mb-4"
                id="outlined-basic"
                label="Enter Notification Title"
                variant="outlined"
                size="small"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <TextField
                className="mb-4"
                id="outlined-basic"
                label="Enter Notification Description"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
            <div className="mb-4">
              <label className="form-label col-12" htmlFor="uploadProfile">
                <b>Notification Image</b>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control col-12"
                  id="uploadProfile"
                  onChange={(e) => setUploadImage(e.target.files[0])}
                />
              </label>
            </div>
            <Button
              fullWidth
              className="my-2 muibtn"
              variant="contained"
              color="primary"
              disabled={isProcessing}
              onClick={() => addNotification()}
            >
              {isProcessing ? (
                <CircularProgress sx={{ color: 'white' }} size={27} />
              ) : (
                <>
                  <Iconify icon={'mdi:plus-thick'} style={{ marginRight: 10 }} />
                  {!isupdate ? 'Add Notification' : 'Update Notification'}
                </>
              )}
            </Button>
            <small className="text-danger text-center">All users will recive this notification</small>
          </div>
        </Box>
      </Modal>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
          <Button onClick={() => handleOpen()} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Send New Notification
          </Button>
        </Stack>

        <Card>
          <DataGrid
            style={{ height: '65vh', width: '100%' }}
            columns={columns}
            rows={rows}
            // getRowId={(Rows) => Rows._id}
            pageSize={6}
            rowsPerPageOptions={[5]}
            loading={loading}
            disableSelectionOnClick
          />
        </Card>
      </Container>
    </div>
  );
}
