import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDwE0xLsFdlEworQycm7rsvtVfNT8wGXOw',
  authDomain: 'vansa-811ee.firebaseapp.com',
  projectId: 'vansa-811ee',
  storageBucket: 'vansa-811ee.appspot.com',
  messagingSenderId: '563285288778',
  appId: '1:563285288778:web:e7907dfe8fa0fad8f083b3',
  measurementId: 'G-D7KKCDQGPV',

  // apiKey: 'AIzaSyBWbYXPrjmvn9JZgTNw6m5Wef5idNRKIHI',
  // authDomain: 'vanca-76b53.firebaseapp.com',
  // projectId: 'vanca-76b53',
  // storageBucket: 'vanca-76b53.appspot.com',
  // messagingSenderId: '492581020165',
  // appId: '1:492581020165:web:dd5644444e72cbb407726c',
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
