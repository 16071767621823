import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  FormControl,
  Box,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  Modal,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import DeleteConfirmationDialog from '../components/Dialogues/DeleteConfirmation';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import CategoryLIST from '../_mock/Category';

//Firebase
import { collection, onSnapshot, getDoc, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';

//Toaster
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [{ id: 'category', label: 'Category', alignRight: false }, { id: '' }];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_Category) => _Category.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  p: 4,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
};

export default function Listings() {
  const [open, setOpen] = useState(null);
  const [openView, setOpenView] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedCategoryToDelete, setSelectedCategoryToDelete] = useState(null);

  const [selectedCategoryToView, setSelectedCategoryToView] = useState(null);

  const [CategoryLIST, setCategoryLIST] = useState([]);

  const [isupdate, setisupdate] = useState('');

  const [Name, setName] = useState('');

  const [isProcessing, setisProcessing] = useState(false);

  const [loading, setLoading] = useState(false); //To check if data if loaded or not from collection.

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const categoriesDocRef = doc(db, 'data', 'categories');

      onSnapshot(categoriesDocRef, async (snapshot) => {
        const categoryData = snapshot.data();
        const categories = categoryData ? categoryData.list : [];

        setCategoryLIST(categories);
        setLoading(false);
      });
    };

    getData();
  }, []);

  const handleCloseView = () => {
    setOpenView(null);
    handleCloseMenu();
  };
  const handleOpenView = (event) => {
    setOpenView(true);
  };
  const handleOpenDeleteDialog = (category) => {
    setDeleteDialogOpen(true);
    setSelectedCategoryToDelete(category);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedCategoryToDelete(null);
    setDeleteDialogOpen(false);
    handleCloseMenu();
  };
  const handleOpenMenu = (event, Category) => {
    setSelectedCategoryToView(Category);
    setSelectedCategoryToDelete(Category.id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = CategoryLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
    setName('');
  };
  const handleOpenUpdate = (name = '') => {
    setName(name);
    setisupdate(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setName('');
    setisupdate('');
    setisProcessing(false);
  };

  const addCategory = () => {
    if (!Name) {
      toast.error('Category Cant be Empty');
      return;
    }
    setisProcessing(true);

    if (isupdate) {
      const updatedCategoryList = CategoryLIST.map((category) => (category === isupdate ? Name : category));
      updateCategories(updatedCategoryList);
    } else {
      // Check if the category already exists in CategoryLIST
      if (CategoryLIST.includes(Name)) {
        toast.error('Category already exists');
        setisProcessing(false);
        return;
      }

      const updatedCategoryList = [...CategoryLIST, Name]; // Add the updated category to CategoryLIST
      updateCategories(updatedCategoryList);
    }
  };

  const updateCategories = async (updatedCategoryList) => {
    const categoriesDocRef = doc(db, 'data', 'categories');
    const categoriesDoc = await getDoc(categoriesDocRef);

    if (categoriesDoc.exists()) {
      // Document exists, update it
      updateDoc(categoriesDocRef, { list: updatedCategoryList })
        .then(() => {
          handleClose();
          toast.success('Category Updated');
        })
        .catch((error) => {
          handleClose();
          toast.error('Error! unable to Update Category');
          console.log(error);
        });
    } else {
      // Document doesn't exist, create a new one
      setDoc(categoriesDocRef, { list: updatedCategoryList })
        .then(() => {
          handleClose();
          toast.success('Category Added');
        })
        .catch((error) => {
          handleClose();
          toast.error('Error! unable to Add Category');
          console.log(error);
        });
    }
  };

  const deleteSelectedData = async () => {
    try {
      // Filter out elements that are present in the selected array
      const updatedList = CategoryLIST.filter((Category) => !selected.includes(Category));

      // Update the document in Firebase with the updated list
      await updateDoc(doc(db, 'data', 'categories'), { list: updatedList });

      // Update the local state with the updated list
      setCategoryLIST(updatedList);

      toast.info('Selected categories deleted');
    } catch (error) {
      toast.error('Error! Unable to delete selected categories');
      console.log(error);
    }
  };

  const onDelete = async () => {
    try {
      // Update the CategoryLIST state
      const updatedList = CategoryLIST.filter((Category) => Category !== selectedCategoryToDelete);

      // Update the document in Firebase
      await updateDoc(doc(db, 'data', 'categories'), { list: updatedList });

      // Update the local state with the updated list
      setCategoryLIST(updatedList);

      toast.info('Category Deleted');
    } catch (error) {
      toast.error('Error! Unable to Delete Category');
      console.log(error);
    }

    handleCloseDeleteDialog();
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - CategoryLIST.length) : 0;

  const filteredCategorys = applySortFilter(CategoryLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredCategorys.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Categories | vPro </title>
      </Helmet>

      <Container>
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Category</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete the category?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={() => onDelete()} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <Box style={{ display: 'flex', justifyContent: 'end' }}>
                <Iconify icon="mdi:close-circle" onClick={handleClose} style={{ cursor: 'pointer', color: 'gray' }} />
              </Box>
              <h2 className="mb-4" mb={3}>
                {!isupdate ? 'Add New Category' : 'Update Category'}
              </h2>

              <FormControl fullWidth size="small">
                <TextField
                  className="mb-4"
                  id="outlined-basic"
                  label="Enter Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              <Button
                fullWidth
                className="mt-2 muibtn"
                variant="contained"
                color="primary"
                disabled={isProcessing}
                onClick={() => addCategory()}
              >
                {isProcessing ? (
                  <CircularProgress sx={{ color: 'white' }} size={27} />
                ) : (
                  <>
                    <Iconify icon={'mdi:plus-thick'} style={{ marginRight: 10 }} />
                    {!isupdate ? 'Add Category' : 'Update Category'}
                  </>
                )}
              </Button>
            </div>
          </Box>
        </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Categories
          </Typography>

          <div>
            <Button onClick={() => handleOpen()} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              Add New Category
            </Button>
          </div>
        </Stack>
        <Card>
          <UserListToolbar
            onDelete={deleteSelectedData}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={CategoryLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredCategorys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => {
                    const selectedCategory = selected.indexOf(category) !== -1;

                    return (
                      <TableRow hover key={category} tabIndex={-1} role="checkbox" selected={selectedCategory}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedCategory} onChange={(event) => handleClick(event, category)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {category}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleOpenUpdate(category);
                            }}
                            sx={{ mr: 2 }}
                          >
                            <Iconify icon={'mdi:lead-pencil'} sx={{ mr: 2 }} />
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            className="bg-danger"
                            onClick={() => handleOpenDeleteDialog(category)}
                          >
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={CategoryLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
