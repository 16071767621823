import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import DeleteConfirmationDialog from '../components/Dialogues/DeleteConfirmation';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';

//Firebase
import { collection, onSnapshot, getDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';

//Toaster
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'date', label: 'Posted On', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'sellerId', label: 'Vendor', alignRight: false },
  { id: 'isFeatured', label: 'Featured', alignRight: false },
  { id: 'website', label: 'Website', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ServiceListings() {
  const [open, setOpen] = useState(null);
  const [openView, setOpenView] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);

  const [selectedUserToView, setSelectedUserToView] = useState(null);

  const [USERLIST, setUSERLIST] = useState([]);

  const [loading, setLoading] = useState(false); //To check if data if loaded or not from collection.

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      let collectionRef = collection(db, 'services');

      onSnapshot(collectionRef, async (data) => {
        const promises = [];
        setUSERLIST([]);
        data.docs.forEach((data) => {
          const listingData = data.data();
          const vendorId = listingData.vendorId;

          // Query the users collection to get the fullName based on vendorId
          const userDocRef = doc(db, 'users', vendorId);
          const promise = getDoc(userDocRef).then((userDoc) => {
            if (userDoc.exists()) {
              const userData = userDoc.data();
              const fullName = userData.fullName;

              // Combine the fullName with other listing data
              const listingWithFullName = { ...listingData, id: data.id, sellerFullName: fullName };
              setUSERLIST((rows) => [...rows, listingWithFullName]);
            }
          });

          promises.push(promise);
        });

        // Wait for all promises to complete
        await Promise.all(promises);

        setLoading(false);
      });
    };
    getData();
  }, []);

  const handleCloseView = () => {
    setOpenView(null);
    handleCloseMenu();
  };
  const handleOpenView = (event) => {
    setOpenView(true);
  };
  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedUserToDelete(null);
    setDeleteDialogOpen(false);
    handleCloseMenu();
  };
  const handleOpenMenu = (event, user) => {
    setSelectedUserToView(user);
    setSelectedUserToDelete(user.id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleFeaturedChange = (id, isFeatured) => {
    console.log('ID', id);
    updateDoc(doc(db, 'services', id), { isFeatured: !isFeatured })
      .then(() => {
        if (isFeatured) {
          toast.info('Service Removed From Featured');
        } else {
          toast.success('Service Added To Featured');
        }
      })
      .catch((error) => {
        toast.error('Error. Try Again');
        console.log(error);
      });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const deleteSelectedData = async () => {
    selected.forEach((documentId) => {
      if (documentId) {
        deleteDoc(doc(db, 'services', documentId))
          .then(() => {
            toast.info('Service with ID ' + documentId + ' deleted successfully!');
            // Additional handling if needed
          })
          .catch((error) => {
            toast.error('Error deleting Service with ID ' + documentId);
            console.error('Error deleting Service with ID ' + documentId, error);
            // Additional error handling if needed
          });
      }
    });
    setSelected([]);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Services | vPro </title>
      </Helmet>

      <Container>
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          heading="Delete Service"
          subtitle="service"
          docId={selectedUserToDelete}
          colName="services"
        />

        <Dialog open={Boolean(openView)} onClose={handleCloseView} fullWidth maxWidth="sm">
          <DialogTitle>Listing Details</DialogTitle>
          <DialogContent>
            {selectedUserToView && (
              <div className="row">
                {selectedUserToView?.images?.map((image, index) => (
                  <div className="col-sm-5 my-2" key={index} style={{ maxHeight: '240px', overflow: 'hidden' }}>
                    <img src={image} className="h-100 w-auto" style={{ objectFit: 'cover' }} alt="" />
                  </div>
                ))}

                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="h6">{selectedUserToView.title}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Id:</TableCell>
                        <TableCell>{selectedUserToView.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Price:</TableCell>
                        <TableCell>{selectedUserToView.price}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Posted on:</TableCell>
                        <TableCell>{selectedUserToView.createdOn?.toDate().toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Island:</TableCell>
                        <TableCell>{selectedUserToView.island}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category:</TableCell>
                        <TableCell>{selectedUserToView.category}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Condition:</TableCell>
                        <TableCell>{selectedUserToView.condition}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Rating:</TableCell>
                        <TableCell>{selectedUserToView.rating}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Facilities:</TableCell>
                        <TableCell>{selectedUserToView.facilities}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Description:</TableCell>
                        <TableCell>{selectedUserToView.description}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Services
          </Typography>
          <div>
            {/* <Link to="/dashboard/addadmin">
              <Button variant="contained" className="me-4" startIcon={<Iconify icon="eva:plus-fill" />}>
                New Admin
              </Button>
            </Link> */}
            {/* <Link to="/dashboard/adduser">
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                New User
              </Button>
            </Link> */}
          </div>
        </Stack>
        <Card>
          <UserListToolbar
            onDelete={deleteSelectedData}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      name,

                      isFeatured,
                      email,
                      phone,
                      sellerFullName,
                      id,
                      category,
                      createdOn,
                      website,
                      location,
                    } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell style={{ minWidth: '200px' }} align="left">
                          {phone}
                        </TableCell>
                        <TableCell style={{ minWidth: '200px' }} align="left">
                          {createdOn?.toDate().toLocaleString()}
                        </TableCell>
                        <TableCell style={{ minWidth: '200px' }} align="left">
                          {location}
                        </TableCell>

                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}
                        <TableCell align="left">{category}</TableCell>
                        <TableCell align="left">{sellerFullName}</TableCell>
                        <TableCell align="left">
                          <Switch checked={isFeatured} onChange={() => handleFeaturedChange(id, isFeatured)} />
                        </TableCell>
                        <TableCell align="left">{website}</TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={() => handleOpenView()} sx={{ mr: 2 }}>
          <Iconify icon={'carbon:view-filled'} sx={{ mr: 2 }} />
          View
        </MenuItem> */}

        <MenuItem onClick={() => handleOpenDeleteDialog()} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
